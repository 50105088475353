import React from "react";

const CurveShape = () => {
  return (
    <div className="position-relative">
      <div className="shape shape-bottom shape-fluid-x svg-shim text-light">
        <svg
          viewBox="0 0 2880 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 48h2880V0h-720C1442.5 52 720 0 720 0H0v48z"
            fill="currentColor"
          />
        </svg>
      </div>
    </div>
  );
};

export default CurveShape;
